// Messages
$success: #68ac35;
$success-hover: #4c8524;
$error: #e4002b;
$link: #0048D9;

// App palette oldPrimary (f74d00)
$primary-hover: #ff7c41;
$primary: #ff6600;
$secondary: #d93501;
$secondary-hover: #b1381d;
$white-hover: rgba(255, 255, 255, 0.3);
$black-hover: #727272;
$link: #0048D9;
$link-hover: #3069dc;

$orange-for-upload: #ff7c41;

$accent: #f6b04e;
$accent-hover: #f8cc8e;
$blue-accent: #17238c;

$statusSuccess: #68AC35;
$statusError: #ED0131;
$statusWarning: #FFD573;
$statusWarningDark: #864022;
$statusDisabled: #595959;

// Grayscale
$black: #333333;
$gray1: #727272;
$gray2: #ababab;
$gray3: #dbdbdb;
$gray4: #efefef;
$white: #ffffff;
$soft-gray: #f9f9f9;
$text-gray: #7e7e7e;

$page-content-bg: #f9fafc;

// Media Queries
$media-xxs: 480px;
$media-xs: 600px;
$media-sm: 960px;
$media-md: 1280px;
$media-lg: 1920px;

$tour-skip-link-color: $primary;
$tour-next-text-color: $white;
$tour-next-button-color: $primary;
$tour-next-button-hover: $primary-hover;
$tour-orb-color: $primary;
// $tour-skip-link-color : Skip button color.
// $tour-text-color : Color of the text that is in the tour step box.
// $tour-next-text-color : Next button text color.
// $tour-zindex : Base z-index for the tour.
// $tour-orb-color : Color of the orb to start a tour.
// $tour-next-button-color : Next button color.
// $tour-next-button-hover : Next button hover color.
// $tour-back-button-color : Back button color.
// $tour-shadow-color : Shadow backdrop that is used for the tour.
// $tour-step-color : Background color for the tour step box.